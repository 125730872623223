import React from 'react'
import Layout from '../components/layout';
import '../styles/about-us.scss';
import {StaticImage} from "gatsby-plugin-image";

const Page = () => (

  <Layout>
    <section className='section-about-us'>
      <div className='about-us-container'>
        <p className='about-text'>
          Today, we're a partner to sellers of all sizes – large, enterprise-scale businesses with complex commerce operations,
          sellers just starting out, as well as merchants who began selling with DD Zone, Inc. and have grown larger over time.
          Whether it's the food truck that's establishing a brick & mortar restaurant, the former sole proprietor
          adding her first employees, or the entrepreneur expanding from one location to ten, as our sellers scale,
          so do our solutions. We all grow together.
        </p>

        <p className='about-text'>
          There is a massive opportunity in front of us. We're building a business that is big, meaningful, and lasting.
          And we are helping sellers around the world do the same.
        </p>

        <p className='about-text'>
          We help our clients to build large digital strategies that address the full guest experience,
          from ordering on a mobile device to purchasing on-site right up through food delivery.
        </p>

        <h2 className='mission-title'>Our Mission</h2>
        <p className='about-text'>
          Our mission is to create solutions for profitability and success.
          We offer integrated and synergistic technology and marketing solutions for global businesses.
          We are dedicated to developing fully customized, accountable programs to address each client's unique situation.
          We are further committed to analyzing the analytics data we collect and translating it into kernels of actionable
          business intelligence that can directly impact each client's growth and success.
        </p>
      </div>

      <div className='our-values-container'>
        <h2 className="our-values-title">Our Values</h2>

        <div className='our-values'>
          <div className='value-container'>
            <div>
              <StaticImage className='value-icon' src='../images/idea.svg' alt='Idea'
                   loading='lazy' />
            </div>
            <div className='value-content'>
              <h5 className='value-title'>
                Industry Leaders </h5>
              <div>
                <p>We provide an amazing product and out-of-this-world service which, combined, deliver premium quality to all our customers.</p>
              </div>
            </div>
          </div>

          <div className='value-container'>
            <div>
              <StaticImage className='value-icon' src='../images/heart.svg' alt='Heart'
                   loading='lazy' />
            </div>
            <div className='value-content'>
              <h5 className='value-title'>
                Customer Focused </h5>
              <div>
                <p>We are building long-term relationships and are constantly improving our product and service to create a positive difference in our
                  customers’ work and lives.</p>
              </div>
            </div>
          </div>

          <div className='value-container'>
            <div>
              <StaticImage className='value-icon' src='../images/smiley.svg' alt='Smiley'
                   loading='lazy' />
            </div>
            <div className='value-content'>
              <h5 className='value-title'>
                Promote Team Growth and Happiness</h5>
              <div>
                <p>Our success comes from the shared energy, skill, and contributions of every single team member.</p>
              </div>
            </div>
          </div>

          <div className='value-container'>
            <div>
              <StaticImage className='value-icon' src='../images/thinking.svg'
                   alt='Functioning' loading='lazy' />
            </div>
            <div className='value-content'>
              <h5 className='value-title'>
                Ambitious and Hard Working </h5>
              <div>
                <p>We believe there’s no substitute for hard work and ambition, just like our customers, we work hard to succeed.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  </Layout>
);


export default Page;
